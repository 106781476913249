<template>
    <div class="card box widget-social" :class="componentStyle">
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <div class="menu-overlay">
            <Button v-if="editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>
        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-1">
                <div class="social-icon">
                    <i :class="icon + ' fg-lightAmber fs-xxlarge'"></i>
                </div>
                <div class="info flex flex-column">
                    <!-- <span class="value">{{ value }}</span> -->
                    <transition name="valchange" mode="out-in">
                        <h1 :key="getModuleValue().value" class="inline-block mb-0 mt-0 cursor-pointer" @click="showChart('value1')">
                            <!-- <span class="text-lg fg-lightBlue">{{ currentSetpoint.value + currentSetpoint.unit }}</span> -->
                            {{ value.value }}<span class="text-lg">{{ unit }}</span>
                        </h1>
                    </transition>
                    <span class="subtext text-xs">{{ getModuleValue().moduleLabel }}</span>
                    <span class="subtext text-xs">{{ getModuleValue().label }}</span>
                </div>
            </div>
        </div>

        <dialog-chart-single :icon="getModuleValue().icon" :node="getModuleValue()" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>

        <div v-if="entryDialog === true">
            <dialog-io-node
                v-bind:showDialog="entryDialog"
                :dialogObj="ioObj"
                v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                "
                @entryAbort="hideDialog()"
            ></dialog-io-node>
        </div>
        <div v-if="chartDialog === true">
            <dialog-chart v-bind:showDialog="chartDialog" :content="this.nodeEntry" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideValveDialog()"></dialog-chart>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
// import { combineRedisHash } from "@/helpers";
// import SocketioService from '@/services/socketioService.js';

export default defineComponent({
    name: 'dashboardValueBox',
    setup() {},
    components: {
        dialogChartSingle,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            // this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getSynoptoCode: 'types/getSynoptoCode',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (this.getModuleValue.value === store.getters.getNoval || this.loading) return true;
            return false;
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                let background = `bg-${this.content.style.background}`;
                return `box ptrn-${this.content.style.pattern} ${background} fg-${this.content.style.foreground}`;
            } else {
                return '';
            }
        },
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.content.data !== null) {
                if (this.content.data.id !== null) {
                    return `${this.content.data.id}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else {
                return '???';
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        label: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.metadata.name;
            } else {
                return this.content.data.label;
            }
        },
        department: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.metadata.department;
            } else {
                return this.content.data.department;
            }
        },
        timestamp: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.timestamp;
            } else {
                return this.content.data.timestamp;
            }
        },
        _id: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry._id;
            } else {
                return this.content.data._id;
            }
        },
        icon: function () {
            if (this.nodeEntry !== undefined) {
                return this.getModuleValue().icon;
            } else {
                return 'mif-blocked';
            }
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
        value: function () {
            return this.parseValue(this.getModuleValue());
        },
        unit: function () {
            return this.value.unitStr;
        },
    },
    data() {
        return {
            loading: false,
            chartDialog: false,
            entryDialog: false,
            showChart1: false,
            ioDialogData: {},
            ioObj: {},
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        this.loadNodeOnce(this.moduleHash).then((response) => {
            if (response) {
                this.loading = false;
            }
                // const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                // SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                //     if (!err && response) {
                //         if (Array.isArray(response) && response.length > 0) {
                //             this.setNodesFull(response);
                //         }
                //     }
                // }).then(() => { 
                //     if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                //     this.setModuleFetched(this.moduleHash);
                //     this.loading = false;
                // });
        });
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            setNodesFull: 'opcua/setNodesFull',
            loadNodeOnce: 'opcua/loadNodeOnce',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        watchLayout() {
            if (this.readyLayout) {
                // this.getNodes();
            }
        },
        getModuleValue() {
            return this.getNode(this.moduleHash);
        },
        getModuleValueStr() {
            const node = this.getNode(this.moduleHash);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.getParameterStatusIOTree();
            // this.getOPCAppTree();
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items2];
            } else {
                this.items = [];
            }
        },
        toggleChartDialog() {
            const ioData = this.content;
            const _obj = JSON.parse(JSON.stringify(ioData));
            this.ioDialogData = _obj;
            this.chartDialog = true;
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            // console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            // this.ioDialogData = { ...ioData };
            // this.getUserList();
            const _obj = JSON.parse(JSON.stringify(ioData));
            this.ioObj = store.getters.getValue(_obj.data.id);
            // const _obj = { ...ioData };
            this.ioDialogData = _obj;
            // this.selectedKey[_obj.data.id] = true;

            console.log(this.nodes);

            for (let node of this.nodes) {
                if (node.key === this.ioObj.metadata.category) {
                    this.expandNode(node);
                }
            }

            // this.expandedKeys[this.nodes[0].key] = true;

            this.entryDialog = true;
            // this.intval_Value = setInterval(this.updateOpenValue, 1000);
        },
        saveDialog() {
            this.submitted = true;

            if (this.ioDialogData.i) {
                // let _index = this.findIndexById(this.ioDialogData._id);
                // if (_index >= 0) {
                //   this.system_user[_index] = this.ioDialogData;
                //   // SocketioService.setUserList(this.system_user[_index]);
                // } else {
                //   // SocketioService.setUserList(this.ioDialogData);
                // }
                // this.content = {...this.ioDialogData};
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        showChart(value) {
            if (value === 'value1') this.showChart1 = true;
        },
        parseValue(val) {
            const value = { ...val };
            if (value.unit === '#BOOL') {
                if (value.value === true) value.value = '🟢Ein';
                else value.value = '🔴Aus';
            }
            return value;
        },
    },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-in-enter-active {
  animation: fadeIn linear 0.3s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}
.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}
.slide-fade-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
.pulse-enter-active {
    animation: pulse 1s;
}
.pulse-leave-active {
    animation: pulse 0.5s;
}
@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9); /* increasing the size */
    }
    30% {
        transform: scale(1.1); /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }
    100% {
        transform: scale(1); /* seeting back to initial size */
        opacity: 1;
    }
}
.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}
.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}
.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}
.box {
    padding: 0.1em !important;
    text-align: center;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}
.box p {
    overflow-wrap: break-word;
    hyphens: auto;
}
.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}
.text-normal {
    line-height: 0;
}
.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}
.card:hover .menu-overlay {
    opacity: 1;
}
.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}
.list-leave-active {
    animation: list 0.5s;
}
@keyframes list {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


</style>